
<template>
  <ValidationObserver
    ref="userForm"
    v-slot="{ handleSubmit, failed }"
  >
    <b-toast
      :visible="failed"
      title="Warning"
      variant="warning"
    >
      {{ $t('messages.invalid_form') }}
    </b-toast>
    <form @submit.prevent="handleSubmit(onSubmit)">
      <template v-if="!assistantId && !isAgent">
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          label="สร้างผู้ช่วยสำหรับ"
        >
          <b-select v-model="assistantData.createFor">
            <b-form-select-option value="MYSELF">บัญชีนี้</b-form-select-option>
            <b-form-select-option
              v-if="isOwner"
              value="MASTER"
            >มาสเตอร์</b-form-select-option>
            <b-form-select-option
              v-if="isOwner || isMaster"
              value="AGENT"
            >เอเย่นต์</b-form-select-option>
          </b-select>
        </b-form-group>

        <b-form-group
          v-if="isOwner && ['MASTER', 'AGENT'].includes(assistantData.createFor)"
          label-cols-sm="12"
          label-cols-lg="3"
          label="เลือก Master"
        >
          <MasterSelectInput
            :value="assistantData.masterId"
            hide-label
            @update="masterId => assistantData.masterId = masterId"
          />
        </b-form-group>

        <b-form-group
          v-if="['AGENT'].includes(assistantData.createFor)"
          label-cols-sm="12"
          label-cols-lg="3"
          label="เลือก Agent"
        >
          <AgentsSelectInput
            :value="assistantData.agentId"
            :master-id="assistantData.masterId"
            hide-label
            @update="agentId => assistantData.agentId = agentId"
          />
        </b-form-group>
        <hr>
      </template>
      <b-form-group
        v-if="assistantDetail"
        label-cols-sm="12"
        label-cols-lg="3"
        label="สร้างโดย"
        label-for="creator"
      >
        <b-form-input
          v-if="assistantDetail"
          id="creator"
          :value="assistantDetail.createdBy"
          readonly
        ></b-form-input>
      </b-form-group>
      <b-form-group
        v-if="assistantDetail"
        label-cols-sm="12"
        label-cols-lg="3"
        label="ชื่อเอเย่นต์"
      >
        <b-form-input
          :value="assistantDetail.agentName"
          readonly
        ></b-form-input>
      </b-form-group>
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('user.username')"
        :rules="assistantId?null:'required'"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`ยูสเซอร์เนม  ${assistantId?'':'*'}`"
          label-for="username"
        >
          <b-form-input
            id="username"
            v-model="assistantData.username"
            placeholder="@username"
            :state="errors[0] ? false : null"
            :readonly="!!assistantId"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>

      <ValidationProvider
        v-if="!assistantData.id"
        v-slot="{ errors }"
        :name="$t('user.password')"
        :rules="assistantData.id?null:'required'"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('user.password')} *`"
          label-for="agent_password"
        >
          <b-input-group>
            <b-form-input
              id="agent_password"
              v-model="assistantData.password"
              :type="isShowPassword?'text':'password'"
              :state="errors[0] ? false : null"
              autocomplete="off"
            ></b-form-input>
            <template #append>
              <b-button @click="isShowPassword = !isShowPassword">
                <i
                  v-if="isShowPassword"
                  class="uil uil-eye"
                ></i>
                <i
                  v-else
                  class="uil uil-eye-slash"
                ></i>
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </ValidationProvider>

      <hr>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label="ชื่อ"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="assistantData.name"
        ></b-form-input>
      </b-form-group>
      <ValidationProvider
        v-slot="{ errors }"
        name="phoneNumber"
        :rules="{
          min: 10,
          regex: /^[0-9]+$/,
        }"
      >
        <b-form-group
          :label="$t('user.phone')"
          label-for="phoneNumber"
          label-cols-sm="12"
          label-cols-lg="3"
        >
          <b-form-input
            id="phoneNumber"
            v-model="assistantData.phoneNumber"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            aria-describedby="input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            {{errors[0]}}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <hr>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label="เลือก Role"
        label-for="role"
      >
        <b-select
          id="role"
          v-model="selectedRole"
          :disabled="isFetchingRoles"
        >
          <b-form-select-option value="">กำหนดเอง</b-form-select-option>

          <b-form-select-option
            v-for="(role, roleI) in roleOptions"
            :key="roleI"
            :value="role.value"
          >{{ role.name }} </b-form-select-option>
        </b-select>
      </b-form-group>
      <b-form-group :label="`${$t('roles.permissions')}`">
        <b-row>
          <b-col
            v-for="(permission, index) in filteredPermissions"
            :key="index"
            cols="6"
            class="mb-3"
          >
            <b-form-checkbox
              :id="`permission-${permission.key}`"
              v-model="permission.isSelected"
              switch
            >
              {{ permission.name }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form-group>
      <div class="text-right">
        <b-button
          class="mr-2"
          to="/assistants/list"
          variant="light"
        >
          {{ $t('buttons.back')}}
        </b-button>
        <b-overlay
          :show="isLoading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  components: {
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
  },
  props: {
    assistantDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isShowPassword: false,
      selectedRole: '',
      assistantData: {
        id: null,
        phoneNumber: '',
        username: '',
        password: '',
        name: '',
        permissionIds: [],
        agentId: null,
        masterId: null,
        createFor: 'MYSELF',
      },
      selectedPermission: [],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) =>
        state.assistant.isCreatingAssistant ||
        state.assistant.isUpdatingAssistant,
      isFetchingRoles: (state) => state.role.isFetchingRoles,
    }),
    ...mapGetters([
      'roles',
      'permissions',
      'isAgent',
      'isOwner',
      'isMaster',
      'userInfo',
    ]),
    assistantId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
    assistantPermissions() {
      return this.assistantDetail?.permissions || []
    },
    roleOptions() {
      let roleType =
        this.assistantData.createFor === 'MYSELF' || this.assistantDetail
          ? this.userInfo.roleName
          : this.assistantData.createFor
      let allowedRoleType =
        {
          OWNER: ['OWNER', 'MASTER', 'AGENT'],
          MASTER: ['MASTER', 'AGENT'],
          AGENT: ['AGENT'],
        }[roleType] || []

      return (
        this.roles
          .filter((role) => allowedRoleType.includes(role.agentType))
          .map((role) => {
            return { value: role.id, name: role.name }
          }) || []
      )
    },
    permissionIds() {
      return this.selectedPermission.reduce((acc, val) => {
        if (val.isSelected) {
          acc.push(val.id)
        }
        return acc
      }, [])
    },
    permissionSelectedOptions() {
      const found = this.roles.find((role) => role.id === this.selectedRole)
      return found ? found.permissions : this.permissions
    },
    filteredPermissions() {
      if (this.assistantData.createFor === 'MYSELF') {
        return this.selectedPermission
      } else if (this.assistantData.createFor === 'MASTER') {
        return this.selectedPermission.filter((permission) =>
          ['AGENT', 'MASTER'].includes(permission.type)
        )
      } else {
        return this.selectedPermission.filter((permission) =>
          ['AGENT'].includes(permission.type)
        )
      }
    },
  },
  watch: {
    assistantDetail(val) {
      if (val) {
        this.setData()
      }
    },
    permissions(val) {
      if (val) {
        this.setPermissionData()
      }
    },
    assistantPermissions(val) {
      if (val) {
        this.setPermissionData()
      }
    },
    permissionSelectedOptions(val) {
      if (val) {
        this.setPermissionBySelectedRole()
      }
    },
  },
  async created() {
    await this.fetchRoles({
      limit: '',
      page: '',
      search: '',
      masterId: '',
      agentId: '',
    })
    this.setData()
  },
  methods: {
    ...mapActions([
      'fetchRoles',
      'createAssistant',
      'updateAssistant',
      'fetchAgentsByMaster',
    ]),
    setData() {
      if (this.assistantDetail) {
        const data = JSON.parse(JSON.stringify(this.assistantDetail))
        this.assistantData = {
          ...data,
        }
        if(this.userInfo.agentId === this.assistantData.agentId) {
          this.assistantData.createFor = 'MYSELF'
        } else if(this.isOwner && this.userInfo.agentId === this.assistantData.parentId) {
          this.assistantData.createFor = 'MASTER'
        } else {
          this.assistantData.createFor = 'AGENT'
        }
      }
      this.setPermissionData()
    },
    setPermissionData() {
      this.selectedPermission = []
      if (this.permissions.length > 0) {
        if (this.assistantPermissions.length > 0) {
          this.permissions.forEach((prop) => {
            const found = this.assistantPermissions.find(
              (permission) => permission.key === prop.key
            )
            this.selectedPermission.push({
              ...prop,
              isSelected: !!found,
            })
          })
        } else {
          this.permissions.forEach((prop) => {
            this.selectedPermission.push({
              ...prop,
              isSelected: true,
            })
          })
        }
      }
    },
    setPermissionBySelectedRole() {
      this.selectedPermission.forEach((prop, key) => {
        const found = this.permissionSelectedOptions.find(
          (permission) => permission.key === prop.key
        )
        this.selectedPermission[key].isSelected = !!found
      })
    },
    onSubmit() {
      this.assistantData.permissionIds = this.permissionIds
      switch (this.assistantData.createFor) {
        case 'MYSELF': {
          this.assistantData.agentId = this.userInfo.agentId
          break
        }
        case 'MASTER': {
          this.assistantData.agentId = this.assistantData.masterId
          break
        }
      }

      if (this.assistantData.id) {
        this.updateAssistant({
          id: this.assistantData.id,
          data: this.assistantData,
        })
      } else {
        this.createAssistant(this.assistantData)
      }
    },
  },
}
</script>
